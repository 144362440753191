export const getQtyOptions = (available: boolean, quantity?: number) => {
  const fallback = ['Unavailable']
  if (!quantity || !available) {
    return fallback
  }

  const maxQty = Math.min(10, quantity)
  const options = Array.from({ length: maxQty }, (_, i) => i + 1)

  return options?.length ? options : fallback
}
