import type { CMSVariant, Inventory } from '@/types/sanity'
import { getQtyOptions } from './get-qty-options'
import { buildSrc, buildSrcSetUrls } from '../media-transformer'

export interface MappedVariant {
  id: string
  name: string
  code: string
  available: boolean
  quantity?: number
  description: string
  formattedAmount: string
  formattedCompareAtAmount: string
  inventory: Inventory
  image?: {
    src?: string
    alt?: string
    srcset?: string
  }
  doNotShip: boolean
  doNotTrack: boolean
  metadata: Record<string, string>
  options?: string[] | number[]
}

const transformImage = (image: CMSVariant['image']) => {
  if (!image?.asset?._ref) return undefined

  return {
    src: buildSrc(image, { width: 544, quality: 80, ratio: '3/4' })?.vanityUrl(),
    srcset: buildSrcSetUrls(
      image,
      { width: 544, quality: 80, ratio: '3/4' },
      [1920, 1600, 1366, 1040, 768, 640, 320, 304],
    ),
    alt: image?.alt || '',
  }
}

export const mapVariant = ({
  id = '',
  code = '',
  name = '',
  description = '',
  do_not_ship = false,
  do_not_track = false,
  price,
  inventory,
  metadata = {},
  image,
}: CMSVariant): MappedVariant => {
  const available = inventory?.available || false
  const quantity = do_not_track ? 10 : inventory?.quantity

  return {
    id,
    name,
    code,
    available,
    quantity,
    description,
    formattedAmount: price?.formatted_amount || '',
    formattedCompareAtAmount: price.formatted_compare_at_amount || '',
    image: transformImage(image),
    doNotShip: do_not_ship,
    doNotTrack: do_not_track,
    metadata: metadata,
    inventory,
    options: getQtyOptions(available, quantity),
  }
}
